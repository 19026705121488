.contact-us {
    /* height: 100vh; */
    width: 95vw;
    margin: auto;
    padding-top: 5px;
}

.contact-us>*:not(:last-child) {
    margin-bottom: 15px;
}

.contact-us button {
    width: 100%;
}

.contact-us h4,
.contact-us h5,
.contact-us h6 {
    color: var(--header-color);
}

.contact-us .links {
    margin-bottom: 5px;
}

.contact-us .links a {
    text-decoration: none;
}

.contact-us .links a:not(:last-child) {
    margin-right: 5px;
}

.contact-us .links a:not(:last-child)::after {
    content: '/';
    margin-left: 5px;
    color: #000;
    font-weight: bold;
}