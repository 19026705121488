.home-page-wrapper {
    max-width: 1260px;
    margin: auto;
}

.header {
    display: flex;
    justify-content: flex-end;
}

.header .logo {
    color: #fff;
    padding: 10px;
    border-radius: 6px;
}

.header .logo img {
    height: 100px;
}

.header .profile-picture {
    height: 35px;
    width: 35px;
    margin-top: -15px;
}

.header .action-links {
    margin-left: auto;
    align-self: center;
}

.header .action-links a {
    text-decoration: none;
}

.header .action-links a:not(:last-child)::after {
    content: "/";
    color: black;
    margin-left: 5px;
}

.header .action-links a:not(:first-child) {
    margin-left: 5px;
}

.intro {
    width: 100%;
    margin: auto;
    margin-top: 15px;
    display: flex;
    padding: 35px 15px;
}

.intro .tip-split-logo {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 20%;
}

.intro .tip-split-logo img {
    width: 100px;
    height: 145px;
}

.intro .content {
    display: flex;
    flex-direction: column;
    width: 50%;
    align-content: flex-start;
    padding: 0 10px
}

.intro .content h4 {
    font-size: 28px;
    margin-bottom: 5px;
}

.intro .content h5 {
    font-size: 20px;
}

.intro .action-buttons {
    display: flex;
    flex-direction: column;
    width: 30%;
    align-content: middle;
    justify-content: center;
}

.intro .action-buttons .button {
    width: 50%;
    margin: 7.5px auto;
    font-weight: bold;
}

#more-info {
    margin: 0 0;
    display: flex;
    flex-direction: row;
    flex-flow: wrap;
}

#more-info .info-card {
    margin-top: 25px;
    width: 32%;
}

#more-info .info-card:not(:nth-child(3n)) {
    margin-right: 2%;
}

#more-info .info-card img,
#more-info .info-card video {
    height: 160px;
    margin-top: 15px;
}

.stripe-partnership {
    margin: 25px 0 0;
    display: flex;
    flex-direction: row;
    padding: 25px 0;
}

.stripe-partnership .stripe-logo {
    width: 30%;
}

.footer {
    margin-top: 45px;
    background-color: var(--main-background-color);
    padding: 10px 0 3px;
    box-shadow: 0px 3px 0px 0px rgb(0 0 0 / 5%);
}

.footer h6,
.footer .social-icon {
    color: var(--header-color);
}

.footer .social-icon:not(:last-child) {
    margin-right: 25px;
}

.footer .links {
    margin-bottom: 5px;
}

.footer .links a {
    font-size: 11px;
    text-decoration: none;
}

.footer .links a:not(:last-child) {
    margin-right: 5px;
}

.footer .links a:not(:last-child)::after {
    content: "/";
    margin-left: 5px;
    color: #000;
    font-weight: bold;
}

@media (max-width: 1280px) {
    .home-page-wrapper {
        max-width: 1080px;
    }
}

@media (max-width: 1080px) {
    .home-page-wrapper {
        max-width: 960px;
    }

    #more-info .info-card img,
    #more-info .info-card video {
        margin-top: 0;
    }
}

@media (max-width: 480px) {
    .home-page-wrapper {
        max-width: 100vw;
        padding: 0 10px;
    }

    .intro {
        flex-direction: column;
    }

    .intro .tip-split-logo,
    .intro .content,
    .intro .action-buttons {
        width: 100%;
    }

    .intro .content {
        align-content: center;
    }

    #more-info {
        flex-direction: column;
    }

    #more-info .info-card {
        width: 100%;
    }

    #more-info .info-card img {
        margin-top: 10px;
    }

    .stripe-partnership {
        flex-direction: column;
    }

    .stripe-partnership .stripe-logo {
        width: 100%;
    }
}