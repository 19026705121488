.tip-checkout-form {
    width: 95vw;
    margin: auto;
}

.tip-checkout-form .tip-details {
    text-align: left;
    margin-bottom: 15px;
}

.tip-checkout-form .tip-details .detail,
.tip-checkout-form .total .detail {
    width: 50%;
    display: inline-block;
}

.tip-checkout-form .total {
    text-align: left;
    padding-top: 5px;
    margin-bottom: 35px;
    border-top: 1px solid black;
}

.tip-checkout-form .button {
    margin: 25px 0;
    width: 100%;
}