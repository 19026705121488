.privacy-page {
    width: 95vw;
    margin: auto;
}

.privacy-page h5,
.privacy-page h6 {
    margin-bottom: 15px;
    color: var(--header-color)
}

.privacy-page p {
    text-align: left;
    margin-bottom: 12.5px;
}

.privacy-page .links {
    margin-bottom: 15px;
}

.privacy-page .links a {
    text-decoration: none;
}

.privacy-page .links a:not(:last-child) {
    margin-right: 5px;
}

.privacy-page .links a:not(:last-child)::after {
    content: '/';
    margin-left: 5px;
    color: #000;
    font-weight: bold;
}