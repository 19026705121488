.employee-profile {
    max-width: 100vw;
    overflow: hidden;
}

.employee-profile .employee-profile-header {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 22vh;
    line-height: 20vh;
    background-color: var(--main-background-color);
    z-index: -1;
    border-radius: 150% / 0 0 50% 50%;
}

.employee-profile .employee-profile-header .employee-name {
    margin: 0;
    color: #064887;
}

.employee-profile .employee-logo {
    height: 125px;
    width: 125px;
    margin: auto;
    border-radius: 50%;
    margin-top: 5vw;
}

.employee-profile .event .qr-icon {
    position: absolute;
    transition: all 75ms;
    right: 2%;
    top: 5%;
}

.employee-profile .qr-icon-hidden {
    visibility: hidden;
    opacity: 0;
}

.employee-profile .qr-icon-visible {
    visibility: visible;
    opacity: 1;
}

.employee-schedule .event {
    position: relative;
}

.employee-schedule .event .event-notes-visible {
    visibility: visible;
    opacity: 1;
    max-height: 2000px;
    transition: visibility 300ms, opacity 300ms, max-height 150ms;
}

.employee-schedule .event .event-notes-hidden {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: visibility 300ms, opacity 300ms, max-height 200ms;
}

.employee-schedule .event .day-of-month {
    line-height: 35px;
    width: 35px;
    background-color: var(--main-background-color);
    border-radius: 50%;
    text-align: center;
    font-weight: bold;
    color: var(--header-color);
    display: inline-block;
    margin-bottom: 7.5px;
    white-space: nowrap;
    overflow: hidden;
}

.employee-schedule .event .event-title {
    display: inline-block;
    margin-left: 5px;
    width: 82%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    vertical-align: top;
}

.employee-tipouts {
    margin-bottom: 75px;
}

.employee-tipouts .header {
    margin-bottom: 15px;
}

.employee-tipouts .tip-breakdown {
    height: 120px;
    transition: height 300ms;
}

.employee-tipouts .tip-list,
.employee-tipouts .divider {
    opacity: 0;
    display: none;
}

.employee-tipouts .visible {
    opacity: 1;
    display: block
}

.employee-profile .event .active-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    color: #000;
    line-height: 50px;
    pointer-events: none;
    transition: .4s right;
}


.employee-profile .event .active-icon-close {
    right: 4%;
}

.employee-profile .event .active-icon-open {
    right: 10%;
}

.employee-profile .event .active-icon:after {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px green;
    transition: transform 0.2s, opacity 0.2s;
}

.employee-profile .event .active-icon:hover:after {
    transform: scale(2.45);
    opacity: 0.8;
}

.employee-profile .event .pulsing:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px green;
    transition: transform 0.2s, opacity 0.2s;
    animation: pulsing 2.7s infinite;
}

.employee-profile .event .event-tip-total {
    position: absolute;
    bottom: 5%;
    right: 5%;
}

@keyframes pulsing {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(2);
        opacity: 0;
    }

    100% {
        transform: scale(1);
        opacity: 0;
    }
}

.transfer-drawer {
    display: flex;
    flex-direction: column;
    min-height: 390px;
}

.transfer-drawer .transfer-types {
    display: flex;
    flex-direction: row;
}

.transfer-drawer .transfer-types .transfer-type {
    height: 150px;
    width: 45vw;
    margin: 15px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 1px solid #000;
}

.transfer-drawer .transfer-types .selected {
    border: 2px solid #66A4E0;
}

.transfer-drawer .transfer-info {
    height: 45px;
    display: flex;
    width: 95vw;
    margin: auto;
    align-items: center;
}

.transfer-drawer .transfer-info .value {
    margin-left: auto;
}

.transfer-drawer .caption {
    padding: 10px 0;
    width: 95vw;
    margin: auto;
}

.transfer-drawer .transfer-button {
    width: 95vw;
    margin: 0 auto 15px;
}