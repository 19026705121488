.stripe-information {
    margin-bottom: 35px;
    border: 2px solid #e6ebf1;
    border-radius: 5px;
    margin: 25px auto;
    padding: 5px 0 5px 5px;
  }
  
  .stripe-information p {
    color: #525f7f;
    font-size: 14px;
    text-align: left;
    margin: 0;
    width: 65%;
  }

  .details-header {
      text-align: left;
      color: #8798ab;
      margin: 0;
  }

  .save-continue-button {
      width: 95vw;
      margin-bottom: 45px!important;
  }

  .company-sizes {
    display: flex;
    flex-direction: row;
  }

    .company-sizes .company-size {
      width: 32%;
      height: 85px;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .company-sizes .company-size:not(:last-child) {
      margin-right: 2%;
    }

    .company-sizes .selected {
      border: 1px solid rgb(25 118 210 / 50%);
      box-shadow: 0px 3px 5px -1px rgb(25 118 210 / 20%), 0px 6px 10px 0px rgb(25 118 210/ 14%), 0px 1px 18px 0px rgb(25 118 210 / 12%);
    }