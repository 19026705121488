.scheduled-employees {
    margin-top: 5px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
}

.name-chip:not(:nth-child(3n+1)) {
    margin-left: 8px;
    margin-bottom: 5px;
}