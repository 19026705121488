.tip-amount-form {
    width: 100vw;
    margin-top: 15px;
    text-align: center;
}

.tip-amount-form .button {
    display: block;
    margin: 15px auto 25px;
}

.tip-amount-form .chips {
    justify-content: center;
    margin-bottom: 45px;
}