.tip-info {
    width: 95vw;
    margin: auto;
    text-align: left;
    margin-top: 10px;

}

.tip-info div:not(:last-child) {
    margin-bottom: 10px;
}