.ios-prompt {
    position: fixed;
    bottom: 1%;
    right: 1vw;
    -webkit-animation-duration: 1.5s;
    animation-duration: 1.5s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}

.ios-prompt .content {
    background: var(--main-background-color);
    width: 98vw;
    margin: auto;
    text-align: center;
    padding: 5px 2px;
    border-radius: 5px;
    z-index: 1;
}

.ios-prompt .arrow {
    height: 25px;
    width: 25px;
    transform: rotate(45deg);
    background: var(--main-background-color);
    margin: auto;
    margin-top: -20px;
    position: relative;
    z-index: -1;
    border-radius: 2px;
}

@-webkit-keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        -webkit-transform: translateY(0);
    }

    40% {
        -webkit-transform: translateY(-30px);
    }

    60% {
        -webkit-transform: translateY(-15px);
    }
}

@keyframes bounce {

    0%,
    20%,
    50%,
    80%,
    100% {
        transform: translateY(0);
    }

    40% {
        transform: translateY(-30px);
    }

    60% {
        transform: translateY(-15px);
    }
}

.bounce {
    -webkit-animation-name: bounce;
    animation-name: bounce;
}