.business-profile {
    max-width: 100vw;
    overflow: hidden;
}

.business-profile .business-profile-header {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 22vh;
    line-height: 20vh;
    background-color: var(--main-background-color);
    z-index: -1;
    border-radius: 150% / 0 0 50% 50%;
}

.business-profile .business-profile-header .business-name {
    margin: 0;
    color: #064887;
}

.business-profile .business-logo {
    height: 125px;
    width: 125px;
    margin: auto;
    border-radius: 50%;
    margin-top: 5vw;
}

.business-profile .employees-container .send-invite-container {
    width: 95vw;
    margin: auto;
    margin-bottom: 10px;
    display: flex;
    flex-direction: row;
}

.business-profile .employees-container .send-invite-container .send-invite-button {
    margin-left: auto;
}