.schedule {
    margin-bottom: 200px;
    -webkit-user-select: none;
    user-select: none;
}

.schedule .month {
    color: var(--header-color);
    display: inline-block;
    width: 50vw;
}

.schedule .decrease-month,
.schedule .increase-month {
    display: inline-block;
    color: var(--header-color);
}

.schedule .decrease-month {
    margin-right: 10px;
}

.schedule .increase-month {
    margin-left: 10px;
}

.schedule .go-to-today {
    margin-bottom: 15px;
}

.schedule .calendar-grid {
    position: relative;
    width: 95vw;
    margin: auto;
}

.schedule .calendar-grid .add-event {
    position: absolute;
    right: 6vw;
    bottom: -7vw;
}

.schedule .calendar-grid .day {
    width: calc(100% / 7);
    height: 7vh;
    line-height: 7vh;
    border: 1px solid #000;
    display: inline-block;
}

.schedule .calendar-grid .day span {
    display: inline-flex;
    height: 45px;
    width: 45px;
    text-align: center;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
    justify-content: center;
    border-radius: 50%;
    position: relative;
}

.schedule .calendar-grid .day .selected {
    background: var(--main-background-color);
}

.schedule .calendar-grid .day .hasEvents::after {
    content:"\A";
    width: 7px;
    height: 7px;
    border-radius:50%;
    background: var(--header-color);
    position: absolute;
    bottom: 5px;
}

.schedule .create-event-header {
    color: var(--header-color);
}

.schedule .events {
    margin-top: 65px;
}

.schedule .events .event .event-notes .title {
    margin-top: 10px;
    font-weight: bold;
}

.schedule .events .event .qr-icon,
.schedule .events .event .edit-icon {
    position: absolute;
    transition: all 75ms;
}

.schedule .events .event .qr-icon {
    right: 10%;
    top: 4%;
}

.schedule .events .event .edit-icon {
    right: 3%;
    top: 4%;
}

.schedule .events .event .qr-icon-hidden,
.schedule .events .event .edit-icon-hidden {
    visibility: hidden;
    opacity: 0;
}

.schedule .events .event .qr-icon-visible,
.schedule .events .event .edit-icon-visible {
    visibility: visible;
    opacity: 1;
}

.schedule .events .event .event-notes-visible {
    visibility: visible;
    opacity: 1;
    max-height: 2000px;
    transition: visibility 300ms, opacity 300ms, max-height 150ms;
}

.schedule .events .event .event-notes-hidden {
    visibility: hidden;
    opacity: 0;
    max-height: 0;
    transition: visibility 300ms, opacity 300ms, max-height 200ms;
}

.schedule .events .event .event-tip-total {
    position: absolute;
    right: 5%;
    bottom: 5%;
}

.schedule .events .event .active-icon {
    position: absolute;
    width: 15px;
    height: 15px;
    background: green;
    border-radius: 50%;
    text-align: center;
    font-size: 30px;
    color: #000;
    line-height: 50px;
    pointer-events: none;
    transition: right .3s;
  }
  
.schedule .events .event .active-icon:after {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px green;
    transition: transform 0.2s, opacity 0.2s;
}
  
.schedule .events .event .active-icon:hover:after {
    transform: scale(2.45);
    opacity: 0.8;
}

.active-icon-close {
    right: 4%;
}

.active-icon-open {
    right: 19%;
}
  
.schedule .events .event .pulsing:before {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;
    content: '';
    box-shadow: inset 0 0 0 3px green;
    transition: transform 0.2s, opacity 0.2s;
    animation: pulsing 2.7s infinite;
}

@keyframes pulsing {
    0% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(2);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}